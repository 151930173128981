// Bootstrap vars
$screen-xs-max:                   767px !default;
$navbar-default-link-color:       #777 !default;
$navbar-padding-vertical:         10px !default;
$navbar-padding-horizontal:       15px !default;
$navbar-default-bg:               #f8f8f8 !default;
$navbar-inverse-bg:               #222 !default;

// Offcanvas vars
$offcanvas-width:                 250px !default;
$offcanvas-animation-time:        .15s !default;
$offcanvas-toggle-background:     #f8f8f8 !default;
$offcanvas-toggle-bars-color:     #000 !default;
$offcanvas-media:                 $screen-xs-max!default;
