/*
Theme Name: PRiNZ SpiegelMagazine PRO default (green/orange) color scheme

Colors:	Green:	#5b6a2d
		Orange: #e18223
		Darker Orange: #d37a22

STRUCTURE OF THIS CSS DOCUMENT

	- HTML ELEMENTS
		+ HYPERLINKS
	- IMAGES
	- MAIN STRUCTURE
	- NAVIGATION BAR STYLES
		+ ESSENTIAL STYLES
		+ PRIMARY MENU
		+ SECONDARY MENU
	- SIDEBAR
	- HEADLINES
	- CONTENT
		+ POST FORMATS
		+ PAGE NAVIGATION LINKS
		+ FORM ELEMENTS
		+ COMMENTS
	- SPECIAL STYLES FOR ADDITIONAL PAGE TEMPLATES
		+ 3-COLUM-PAGE
		+ FEATURED PAGE
		+ FULLWIDTH PAGE
	- FOOTER
	- FLOAT PROPERTIES


/******************************************
*   HTML Elements
******************************************/
body
{
    padding: 13px 0 25px 0;

    color: #333;
    background: #eee;
}

body,
select,
input,
textarea
{
    font: 12px Verdana, Arial, Sans-Serif;
    line-height: 1.5em;
}

p
{
    margin: 15px 0;
}

hr
{
    display: block;

    height: 4px;

    border: 0;
    background: #ddd;
}

small
{
    font-size: .9em;

    padding: 3px 0;
}

/******* Hyperlinks ********************/

/* standard link behaviour */
a:link,
a:active,
a:visited
{
    text-decoration: underline;

    color: #2675be;
}

a:hover
{
    text-decoration: none;

    color: #2675be;
}

/* Style the Excerpt "Continue Reading" Link*/
a.excerptmore
{
    text-decoration: none;

    color: #e18223;
}

a:hover.excerptmore
{
    text-decoration: underline;
}

/* HTML headline links */
h3 a:link,
h3 a:active,
h3 a:visited
{
    text-decoration: none;
}

h3 a:hover
{
    text-decoration: underline;
}

h4 a:link,
h4 a:active,
h4 a:visited
{
    text-decoration: none;

    color: #666;
}

h4 a:hover
{
    text-decoration: underline;
}

/* Links in the sidebar */
#sidelist a:link,
#sidelist a:active,
#sidelist a:visited
{
    text-decoration: none;
}

#sidelist a:hover
{
    text-decoration: underline;
}

#sidelist .children li a,
#sidelist .children li a:visited
{
    padding: 2px 2px 2px 0;

    color: #666;
    border: 0;
    background: #eee;
}

#sidelist .children li a:hover,
#sidelist .children li a:active
{
    color: #000;
}

/* How tags appear */
#tags a
{
    text-decoration: none;
}

#tags a:hover
{
    text-decoration: underline;
}

/* Links within comments metadata (date, Author etc.) */
.commentmetadata a:link,
.commentmetadata a:active,
.commentmetadata a:visited
{
    text-decoration: none;
}

.commentmetadata a:hover
{
    text-decoration: underline;
}

/* Links in the footer */
#footer a
{
    text-decoration: none;
}

#footer a:hover
{
    text-decoration: underline;
}

/****** Images ****************************/

#logo img,
#logo a:link img,
#logo a:visited img
{
    width: 100%;
    height: auto;
    padding: 0;

    border: none;
    outline: none;
    background: none;
}

#featured img,
#featured a:link img,
#featured a:visited img
{
    width: 100%;
    height: auto;
    margin: 0 0 10px 0;
}

.home-cat-img
{
    float: left;

    margin: 0 6px 4px 0;
}

#leadcontainer img,
#leadcontainer a:link img,
#leadcontainer a:visited img
{
    padding: 8px;

    border: 1px solid #f9f9f9;
    outline: 1px solid #eee;
    background: #f4f4f4;
}

/***** Alignments for WordPress  ************/

.aligncenter
{
    display: block;

    margin: 0 auto;
}

.alignright
{
    float: right;

    margin: 4px 0 10px 10px;
}

.alignleft
{
    float: left;

    margin: 0 10px 10px 0;
}

#featured .alignleft
{
    margin: 4px 10px 10px 0;
}

#homecontent-bottom .alignleft
{
    float: left;

    margin: 16px 10px 0 0;
}

/******************************************
*   Main Element Structure
******************************************/

.searchcontainer
{
    position: relative;
    z-index: 999;

    float: right;

    margin-top: -125px;
}

#page
{
    /* main container */
    font-size: 1em;

    padding: 0 20px 40px 20px;

    border-top: 4px solid #8dbd3e;
    border-bottom: 4px solid #8dbd3e;
    background: #fff;
    -webkit-box-shadow: 0 3px 8px rgba(0, 0, 0, .25);
       -moz-box-shadow: 0 3px 8px rgba(0, 0, 0, .25);
}

.left
{
    float: left;
}

.right
{
    float: right;
}

/* Featured articles on the homepage */
#featured p
{
    margin: 0 0 5px 0;
}

#featured div .more
{
    margin-bottom: 5px;
    padding-left: 20px;

    color: #e18223;
    background: url(../images/icons/icon_sidebar-list.png) no-repeat;
}

#featured div .more a
{
    text-decoration: none;

    color: #e18223;
}

#featured div .more a:hover
{
    text-decoration: underline;
}

#featured .byline,
#featured .byline a
{
    font-size: 10px;

    margin: 0 0 5px 0;

    color: #5b6a2d;
}

#featured h4
{
    /* used for the title link of the Featured Post widget "mmore from this category list" */
    color: #666;
}

.homebox
{
    margin-bottom: 10px;
}

/***********************************************
*   STYLES FOR THE HORIZONTAL NAVIGATION MENUES
************************************************/

/*** ESSENTIAL STYLES FOR ALL MENUS ***/

#nav
{
    position: relative;

    height: 30px;

    background: none repeat scroll 0 0 #8dbd3e;
}

#nav ul
{
    margin: 0;
    padding: 0;
}

#nav li
{
    position: relative;

    float: left;

    width: auto;

    list-style: none;
}

#nav li.active
{
    background: #2675be;
}

#nav li a
{
    display: block;
}

#nav li a,
#nav li a:visited,
#nav li span
{
    line-height: 30px;

    padding: 0 10px;

    text-decoration: none;

    color: #fff;
    background: none repeat scroll 0 0 transparent;
}

#nav li a:focus,
#nav li a:hover,
#nav li a:active,
#nav li.active a
{
    color: #fff;
    outline: 0 none;
    background: none repeat scroll 0 0 #2675be;
}

#nav li ul
{
    position: absolute;
    z-index: 999;

    display: none;

    width: 100%;

    background: #8dbd3e;
}

#nav li li
{
    width: 100%;
}

#nav li li a
{
    line-height: 150%;

    width: 126px;
    height: 30px;
}

#nav li.active li a:link,
#nav li.active li a:active,
#nav li.active li a:visited
{
    display: block;
    float: none;

    background: #8dbd3e;
}

#nav li.active li a:hover
{
    display: block;
    float: none;

    background: #2675be;
}

.invisible
{
    display: none;
}

/******************************************
*   Sidebar stuff exept hyperlinks
******************************************/

#sidelist li
{
    list-style: none;
}

.bullets li
{
    list-style: none;

    border-bottom: 1px solid #fff;
}

#sidelist .bullets li
{
    margin-bottom: 5px;
    margin-left: 22px;

    list-style: square;
}

#sidelist .children
{
    background: #eee;
}

#sidelist .children li
{
    margin-left: 15px;
    padding-left: 15px;

    list-style: none;

    border-bottom: 1px solid #fff;
    background: #eee;
}

#sidelist .recent li
{
    margin-bottom: 5px;
    padding-left: 20px;

    background: url(../images/icons/icon_sidebar-list.png) no-repeat;
}

#sidelist .recent-comments li
{
    margin-bottom: 5px;
    padding-left: 20px;

    background: url(../images/icons/icon_sidebar-list.png) no-repeat;
}

#twitter_update_list li
{
    margin-bottom: 5px;
    padding-left: 20px;

    background: url(../images/icons/icon_sidebar-list.png) no-repeat;
}

#sidelist .more li
{
    margin-bottom: 5px;
    padding-left: 20px;

    background: url(../images/icons/icon_sidebar-list.png) no-repeat;
}

#sidelist .feed li
{
    margin-bottom: 5px;
    padding-left: 20px;

    background: url(../images/icons/rss.gif) no-repeat;
}

/*********************************************************************************
*   All Headlines and related stuff
**********************************************************************************/

.blogtitle,
.blogtitle a
{
    font-size: 55px;
    font-weight: normal;

    padding: 25px 0 25px 0;

    text-decoration: none;
    /* That�s only relevant, if you don�t use an image for your Logo in the header */

    color: #e18223;
}

.description
{
    font-size: 20px;
    font-weight: bold;

    margin: 0 0 10px 45px;

    color: #999;
}

.cat-head
{
    /* Background for the category teasers headline on the homepage */
    margin-bottom: 5px;
    padding: 3px 2px 1px 4px;

    background-color: #5b6a2d;
}

.title-heading,
.title-heading a
{
    font: 12px Verdana, Arial, Helvetica, sans-serif;
    font-weight: bold;

    margin-bottom: 4px;

    text-decoration: none;
    letter-spacing: .1em;
    text-transform: uppercase;

    color: #888;
}

#sidebar .title-heading,
#sidebar .title-heading a
{
    font: 12px Verdana, Arial, Helvetica, sans-serif;
    font-weight: bold;

    margin-bottom: 4px;

    letter-spacing: .1em;
    text-transform: uppercase;

    color: #5b6a2d;
}

h1
{
    font-size: 32px;
    font-weight: bold;

    margin: 0 10px 0 0;
    padding: 20px 0 0 10px;

    color: #5b6a2d;
}

h2
{
    font: 20px Verdana, Arial, Helvetica, sans-serif;
    font-weight: normal;

    margin: 20px 0;
    padding-bottom: 8px;

    color: #2675be;
    border-bottom: 4px solid #ddd;
}

#leadcontainer h2,
#leadcontainer h2 a
{
    line-height: 100%;

    display: block;

    margin: 5px 0 10px 0;
    padding: 0;

    text-decoration: none;

    color: #e18223;
    border: none;
}

#featured h2,
#featured h2 a
{
    font-size: 20px;

    margin: 0;
    padding: 0 0 5px 0;

    text-decoration: none;

    border: none;
}

h3
{
    font: 12px Verdana, Arial, Helvetica, sans-serif;
    font-weight: bold;

    margin-bottom: 4px;

    letter-spacing: .1em;
    text-transform: uppercase;

    color: #8dbd3e;
}

#homecontent-bottom h3,
#homecontent-bottom h3 a
{
    font-size: 16px;
    font-weight: normal;

    margin: 8px 0 15px;

    letter-spacing: normal;
    text-transform: none;

    color: #8dbd3e;
}

#sidebar h3,
#sidebar h2
{
    font-size: .9em;

    margin: 20px 0 15px 0;
    padding: 5px 0 5px 2px;

    color: #2675be;
    border-top: 4px solid #ddd;
    border-bottom: 1px solid #ddd;
}

#respond h3
{
    margin-top: 25px;
    padding-top: 20px;

    border-top: 4px solid #ddd;
}

h3#comments
{
    margin-top: 32px;
}

h4
{
    font-size: 14px;

    margin-top: 0;
    margin-bottom: 6px;

    color: #444;
}

#homecontent-bottom h4,
#homecontent-bottom h4 a
{
    font: 10px Verdana, Arial, Helvetica, sans-serif;
    font-weight: bold;

    margin-bottom: 5px;
    padding: 3px 2px 3px 4px;

    letter-spacing: .1em;
    text-transform: uppercase;

    color: #fff;
    background-color: #2675be;
}

.entry h1
{
    font-size: 2.0em;
    font-weight: normal;

    margin: 10px 0 20px 0;
    padding: 0;

    color: #5b6a2d;
}

.entry h2
{
    font-size: 1.8em;

    margin: 10px 0 20px 0;
    padding: 0;

    color: #5b6a2d;
    border: none;
}

.entry h3
{
    font-size: 1.6em;
    font-weight: normal;

    margin: 10px 0 20px 0;
    padding: 0;

    letter-spacing: 0;
    text-transform: none;

    color: #5b6a2d;
}

.entry h4
{
    font-size: 1.4em;
    font-weight: normal;

    margin: 10px 0 20px 0;
    padding: 0;

    color: #5b6a2d;
}

.entry h5
{
    font-size: 1.2em;
    font-weight: normal;

    margin: 10px 0 20px 0;
    padding: 0;

    color: #5b6a2d;
}

.entry h6
{
    font-size: 1.0em;
    font-weight: normal;

    margin: 10px 0 20px 0;
    padding: 0;

    color: #5b6a2d;
}

/******************************************
*   Content styles
******************************************/

/* some structural styles for the content area */

#homecontent-top
{
    width: 940px;
    /* The upper area on the homepage */
    height: 350px;
    margin: 20px 0;

    background: #ccc;
}

#homecontent-bottom
{
    /* The lower area f the homepage */
    margin-top: 10px;
}

/* The lead story on the honmepage */

#leadcontainer
{
    width: 930px;
    min-height: 330px;
    margin: 2px 0 20px 0;
    padding: 15px 10px 10px 0;

    color: #333;
    border-top: 4px solid #5b6a2d;
    border-bottom: 4px solid #5b6a2d;
    background: #fff;
}

.leadmeta
{
    /* That�s how the Date and Author appear in the leadarticle section */
    font: .9em Arial, Helvetica, sans-serif;

    text-decoration: none;

    color: #666;
}

/**********************************************************/

.description
{
    font-size: 1.3em;
    font-weight: bold;

    color: #999;
}

.title,
a.title
{
    font-size: 1.4em;

    margin: 10px 0 15px 0;

    color: #2675be;
}

/* Pagination formats for the achives (previous and next links) */

.navigation a
{
    font-size: .9em;

    width: 120px;
    padding: 2px 4px 2px 4px;

    text-align: center;
    text-decoration: none;

    color: #666;
    border: 1px dotted #ccc;
}

.navigation a:hover
{
    background-color: #eee;
}

.preventries
{
    float: left;
}

.nextentries
{
    float: right;
}

/******* Google AdSense styles for the banner in the midlle of the homepage **************/

.adsense-banner
{
    padding: 4px;

    text-align: center;
}

/*Post Formatting*/

.entry
{
    padding-bottom: 28px;
}

.post blockquote
{
    font-size: 16px;
    font-style: italic;

    margin: 18px 35px;
    padding: 4px 14px;

    color: #666;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}

.post ul li
{
    margin-bottom: 5px;
    margin-left: 42px;

    list-style: square;
}

.post ol li
{
    margin-bottom: 5px;
    margin-left: 48px;
}

#tags
{
    padding: 5px;

    border-top: 1px solid #999;
    border-bottom: 1px solid #999;
    background-color: #eee;
}

.postmetadata
{
    margin: 30px 0;
}

/* Page navigation links */
#paging
{
    width: 600px;
    height: 30px;

    list-style: none;
}

#paging li
{
    float: left;

    height: 30px;

    text-align: center;
}

#paging li.prev,
#paging li.next
{
    width: 100px;
}

#paging li.pages
{
    width: 300px;
    margin: 0 20px;
}

#paging li.pages a
{
    display: inline-block;

    margin: 0 5px;
    padding: 5px;

    border: 1px solid #ccc;
    background: #eee;
}

#paging li.pages a:hover
{
    border: 1px solid #ccc;
    background: #fff;
}

#paging li.pages a.active
{
    font-weight: bold;

    padding: 7px;

    border: 1px solid #ccc;
    background: #ddd;
}

/* Form Elements */

select
{
    width: 100%;

    border-top: 2px solid #999;
    border-right: none;
    border-bottom: 2px solid #999;
    border-left: none;
    background: #eee;
}

#searchform
{
    padding: 16px 5px 0 0;
}

.searchbutton
{
    font-weight: bold;

    padding: 1px;

    color: #999;
    border: 1px solid #ddd;
    background: #fff;
}

.searchbutton:hover
{
    text-decoration: none;

    color: #666;
    border: 1px solid #999;
    background: #eee;
}

.searchfield
{
    width: 150px;
    padding: 1px 1px 1px 20px;

    color: #666;
    border: 1px solid #ddd;
    background: url(../images/icons/search-16.png) no-repeat #fff;
}

.searchfield:hover,
.searchfield:focus
{
    text-decoration: none;

    border: 1px solid #999;
    background: url(../images/icons/search-16.png) no-repeat #eee;
}

.newsletter
{
    width: 160px;
    padding: 2px 2px 2px 5px;

    color: #666;
    border: 1px solid #ddd;
    background: #fff;
}

.newsletter:hover,
.newsletter:focus
{
    text-decoration: none;

    border: 1px solid #999;
    background: #eee;
}

#newsleter-submit
{
    padding: 1px 2px 1px 5px;

    color: #666;
    border: 1px solid #999;
    background: #eee;
}

#newsleter-submit:hover
{
    border: 1px solid #ddd;
    background: #fff;
}

.button
{
    font-weight: bold;

    padding: 1px;

    color: #666;
    border-top: 2px solid #ddd;
    border-right: none;
    border-bottom: 2px solid #ddd;
    border-left: none;
    background: #fff;
}

.button:hover
{
    text-decoration: none;

    color: #333;
    border-top: 2px solid #999;
    border-right: none;
    border-bottom: 2px solid #999;
    border-left: none;
    background: #eee;
}

#commentform input
{
    width: 170px;
    margin: 5px 5px 1px 0;

    border-top: 2px solid #ddd;
    border-right: none;
    border-bottom: 2px solid #ddd;
    border-left: none;
}

#commentform textarea
{
    width: 100%;
    margin-top: 5px;

    border-top: 2px solid #ddd;
    border-right: none;
    border-bottom: 2px solid #ddd;
    border-left: none;
    background: #eee;
}

#commentform textarea:hover,
#commentform textarea:focus
{
    background: #fff;
}

/* Comments*/

.commentlist
{
    padding-left: 30px;
}

.commentlist li
{
    margin: 15px 0 3px;
    padding: 8px 15px 10px 15px;

    border-top: 1px solid #999;
    border-bottom: 1px solid #999;
    background: #eee;
}

#commentform small
{
    font-weight: bold;

    padding: 0;

    background: #fff;
}

.commentmetadata
{
    display: block;
}

.commentlist small
{
    padding: 2px 5px 2px 5px;

    border-top: 1px solid #999;
    border-bottom: 1px solid #999;
    background: #fff;
}

.commentlist cite
{
    font-weight: bold;
}

/*****************************************************
*   Special styles for the various page templates
****************************************************/

/* Styles for the "3-columns-page template" */

#pageleft
{
    float: left;

    width: 300px;
    margin: 20px 20px 0 0;
}

#pagemiddle
{
    float: left;

    width: 300px;
    margin: 20px 20px 0 0;
}

#pageright
{
    float: right;

    width: 300px;
    margin: 20px 0 0 0;
}

#pageleft h2,
#pageleft h2 a,
#pagemiddle h2,
#pagemiddle h2 a,
#pageright h2,
#pageright h2 a
{
    font-size: 18px;

    margin: 10px 0;
    padding: 0;

    border: none;
}

#pageleft h3,
#pageleft h3 a,
#pagemiddle h3,
#pagemiddle h3 a,
#pageright h3,
#pageright h3 a
{
    color: #888;
}

#pageleft li,
#pagemiddle li,
#pageright li
{
    list-style: none;
}

#pageleft .recent li,
#pagemiddle .recent li,
#pageright .recent li
{
    margin-bottom: 5px;
    padding-left: 20px;

    background: url(../images/icons/icon_sidebar-list.png) no-repeat;
}

#pageleft a:link,
#pageleft a:active,
#pageleft a:visited
{
    text-decoration: none;
}

#pageleft a:hover
{
    text-decoration: underline;
}

#pagemiddle a:link,
#pagemiddle a:active,
#pagemiddle a:visited
{
    text-decoration: none;
}

#pagemiddle a:hover
{
    text-decoration: underline;
}

#pageright a:link,
#pageright a:active,
#pageright a:visited
{
    text-decoration: none;
}

#pageright a:hover
{
    text-decoration: underline;
}

/* Styles for the "featured-page template" */

#featured-top
{
    float: left;

    width: 940px;
    margin: 0 0 25px 0;
    padding: 0 0 15px 0;

    border-bottom: 4px solid #ddd;
}

#featured-content
{
    float: left;

    width: 620px;
}

#featured-sidebar
{
    float: right;

    width: 300px;
}

#featured-sidebar h3
{
    margin: 0 0 20px 0;
    padding: 5px 0 5px 2px;

    border-bottom: 2px solid #ccc;
}

#featured-sidebar .sidebar_widget
{
    margin: 0 0 20px 0;
}

#featured-leftcol
{
    float: left;

    width: 620px;
    margin: 20px 0 0 0;
    padding: 0 10px 0 0;

    border-right: 2px solid #ccc;
}

#featured-leftcol h2,
#featured-content h2
{
    font: 1.6em Verdana, Arial, Helvetica, sans-serif;

    margin: 0 0 10px 0;

    color: #5b6a2d;
    border: none;
}

#featured-rightcol h2
{
    font: .9em Verdana, Arial, Helvetica, sans-serif;
    font-weight: bold;

    margin: 0 0 10px 0;

    letter-spacing: .1em;
    text-transform: uppercase;

    color: #5b6a2d;
    border-bottom: 2px solid #ccc;
}

#featured-leftcol img
{
    padding: 8px;

    border: 1px solid #f9f9f9;
    outline: 1px solid #eee;
    background: #f4f4f4;
}

#featured-rightcol
{
    float: right;

    width: 300px;
    margin-top: 20px;
}

#featured-rightcol li
{
    list-style: none;
}

#featured-rightcol .recent li
{
    margin-bottom: 5px;
    padding-left: 20px;

    background: url(../images/icons/icon_sidebar-list.png) no-repeat;
}

#featured-rightcol a:link,
#featured-rightcol a:active,
#featured-rightcol a:visited
{
    text-decoration: none;
}

#featured-rightcol a:hover
{
    text-decoration: underline;
}

/* Styles for the "fullwidth-page template" */

#fullwidthpage
{
    float: left;

    width: 940px;
}

/******************************************
*   Footer
******************************************/

#footer
{
    font-size: .9em;

    padding-top: 15px;

    text-align: center;

    color: #666;
}

#footer a
{
    color: #333;
}

#alternative_footer
{
    font-size: .9em;

    margin: 0 auto 5px;
    padding: 20px;

    color: #eee;
    border-bottom: 4px solid #8dbd3e;
    background: #8dbd3e;
    -webkit-box-shadow: 0 3px 8px rgba(0, 0, 0, .25);
       -moz-box-shadow: 0 3px 8px rgba(0, 0, 0, .25);
}

#alternative_footer a
{
    color: #eee;
}

.alternative_footer_left
{
    float: left;

    width: 210px;
    padding: 0 20px 0 0;
}

.alternative_footer_middle1
{
    float: left;

    width: 210px;
    padding: 0 20px 0 10px;

    border-left: 1px solid #eee;
}

.alternative_footer_middle2
{
    float: left;

    width: 210px;
    padding: 0 10px;

    border-left: 1px solid #eee;
}

.alternative_footer_right
{
    float: right;

    width: 210px;
    padding: 0 10px;

    border-left: 1px solid #eee;
}

/* Float Properties*/

.clearfix:after
{
    line-height: 0;

    display: block;
    visibility: hidden;
    clear: both;

    height: 0;

    content: '.';
}

.clearfix
{
    display: inline-block;
}

html[xmlns] .clearfix
{
    display: block;
}

* html .clearfix
{
    height: 1%;
}

.clear
{
    clear: both;
}

.clearfloat:after
{
    display: block;
    visibility: hidden;
    clear: both;

    height: 0;

    content: '.';
}

.clearfloat
{
    /*never delete this otherwise MSIE will kill the layout */
    display: inline-block;
}

.clearfloat
{
    /*never delete this otherwise MSIE will kill the layout */
    display: block;
}

#homecontent-bottom ul
{
    margin: 0;
    margin-top: 10px;
    padding: 0;
    padding-left: 15px;
}

#homecontent-bottom ul.slides
{
    padding: 0;
}

#homecontent-bottom li
{
    margin: 0;
    padding: 0;
}

#featured ul
{
    margin-bottom: 18px;
}

#content ul
{
    margin: 0 0 0 20px;
    padding: 0;
}

td
{
    padding: 2px;
}

.ce_teaser.post
{
    padding: 25px 0;

    border-bottom: 4px solid #ddd;
}

/* Mail Chimp */
#mc_embed_signup .mc-field-group
{
    margin-bottom: 5px;
}

#mc_embed_signup label
{
    display: block;
    float: left;

    width: 115px;
}

#mc_embed_signup label.input-group-label
{
    font-weight: bold;

    display: block;
    float: none;

    margin: 20px 0 5px 0;
}

#mc_embed_signup .input-group label
{
    display: inline;
    float: none;
}

#mc_embed_signup #mc-embedded-subscribe
{
    margin: 10px 0 0 0;
    padding: 5px;

    color: #fff;
    border: 0;
    background-color: #2675be;
}

#mc_embed_signup .input-group ul
{
    margin: 0;
    padding: 0;

    list-style: none;
}

#sidelist
{
    margin-bottom: 20px;
}

@media screen and (max-width: $screen-xs-max)
{
    .featured1
    {
        margin-bottom: 15px;
    }

    .alignleft,
    .alignright
    {
        float: none;

        width: 100%;
        height: auto;
        margin: 10px 0 0;
    }
}

.navbar-offcanvas
{
    padding-top: 30px;

    background: #8dbd3e;

    ul
    {
        margin-left: 0;
        padding-left: 0;

        list-style: none;
    }

    a:link,
    a:visited
    {
        text-decoration: none;

        color: #fff;
    }

    li
    {
        padding: 5px;

        &.active
        {
            color: #fff;
            background-color: #2675be;
        }
    }

    ul.level_2
    {
        display: none;

        padding: 10px;
    }

    .ol-toogle-submenu
    {
        font-size: 20px;

        position: absolute;
        top: 5px;
        right: 5px;

        cursor: pointer;

        color: #fff;
    }
}

@media (min-width: 768px)
{
    .navbar-offcanvas
    {
        display: none;
    }
}

@media (max-width: 767px)
{
    #nav
    {
        display: none;
    }
}

.nav-btn-wrap
{
    text-align: center;
}

.offcanvas-toggle
{
    float: none;

    width: 42px;
    margin: 10px 0 0 0;

    background: #8dbd3e;

    .icon-bar
    {
        background-color: #fff;
    }
}

.ol-breadcrumb
{
    font-size: 10px;

    padding: 10px 0;

    h4
    {
        font-size: 10px;

        display: inline-block;
    }

    div
    {
        display: inline;
    }
}

.ce_text table td
{
    vertical-align: top;
}

#sidebar
{
    margin-top: 49px;
}

@media (max-width: $screen-xs-max)
{
    .ce_text table
    {
        tr
        {
            display: block;

            margin-bottom: 10px;

            td
            {
                display: block;

                padding: 5px 10px 0;
            }
        }
    }
}
