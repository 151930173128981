
/*** adding the class sf-navbar in addition to sf-menu creates an all-horizontal nav-bar menu ***/
.sf-navbar {
  background:		#BDD2FF;
  height:			2.5em;
  padding-bottom:	2.5em;
  position:		relative;
}
.sf-navbar li {
  background:		#AABDE6;
  position:		static;
}
.sf-navbar a {
  border-top:		none;
}
.sf-navbar li ul {
  width:			44em; /*IE6 soils itself without this*/
}
.sf-navbar li li {
  background:		#BDD2FF;
  position:		relative;
}
.sf-navbar li li ul {
  width:			13em;
}
.sf-navbar li li li {
  width:			100%;
}
.sf-navbar ul li {
  width:			auto;
  float:			left;
}
.sf-navbar a, .sf-navbar a:visited {
  border:			none;
}
.sf-navbar li.current {
  background:		#BDD2FF;
}
.sf-navbar li:hover,
.sf-navbar li.sfHover,
.sf-navbar li li.current,
.sf-navbar a:focus, .sf-navbar a:hover, .sf-navbar a:active {
  background:		#BDD2FF;
}
.sf-navbar ul li:hover,
.sf-navbar ul li.sfHover,
ul.sf-navbar ul li:hover li,
ul.sf-navbar ul li.sfHover li,
.sf-navbar ul a:focus, .sf-navbar ul a:hover, .sf-navbar ul a:active {
  background:		#D1DFFF;
}
ul.sf-navbar li li li:hover,
ul.sf-navbar li li li.sfHover,
.sf-navbar li li.current li.current,
.sf-navbar ul li li a:focus, .sf-navbar ul li li a:hover, .sf-navbar ul li li a:active {
  background:		#E6EEFF;
}
ul.sf-navbar .current ul,
ul.sf-navbar ul li:hover ul,
ul.sf-navbar ul li.sfHover ul {
  left:			0;
  top:			2.5em; /* match top ul list item height */
}
ul.sf-navbar .current ul ul {
  top: 			-999em;
}

.sf-navbar li li.current > a {
  font-weight:	bold;
}

/*** point all arrows down ***/
/* point right for anchors in subs */
.sf-navbar ul .sf-sub-indicator { background-position: -10px -100px; }
.sf-navbar ul a > .sf-sub-indicator { background-position: 0 -100px; }
/* apply hovers to modern browsers */
.sf-navbar ul a:focus > .sf-sub-indicator,
.sf-navbar ul a:hover > .sf-sub-indicator,
.sf-navbar ul a:active > .sf-sub-indicator,
.sf-navbar ul li:hover > a > .sf-sub-indicator,
.sf-navbar ul li.sfHover > a > .sf-sub-indicator {
  background-position: -10px -100px; /* arrow hovers for modern browsers*/
}

/*** remove shadow on first submenu ***/
.sf-navbar > li > ul {
  background: transparent;
  padding: 0;
  -moz-border-radius-bottomleft: 0;
  -moz-border-radius-topright: 0;
  -webkit-border-top-right-radius: 0;
  -webkit-border-bottom-left-radius: 0;
}