@import "vars";

@media (max-width: $offcanvas-media) {
  .offcanvas-stop-scrolling {
    height: 100%;
    overflow: hidden;
  }

  .navbar-default {
    .navbar-offcanvas {
      background-color: $navbar-default-bg;
    }
  }

  .navbar-inverse {
    .navbar-offcanvas {
      background-color: $navbar-inverse-bg;
    }
  }

  .navbar-offcanvas {
    position: fixed;
    width: 100%;
    max-width: $offcanvas-width;
    left: -$offcanvas-width;
    top: 0;
    padding-left: $navbar-padding-horizontal;
    padding-right: $navbar-padding-horizontal;
    z-index: 999;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    transition: all $offcanvas-animation-time ease-in;

    &.in {
      box-shadow: 0 0 20px rgba(0, 0, 0, .3);
    }

    &.navbar-offcanvas-fade {
      opacity: 0;

      &.in {
        opacity: 1;
      }
    }

    &.offcanvas-transform {
      &.in {
        transform: translateX($offcanvas-width);
      }
    }

    &.offcanvas-position {
      &.in {
        left: 0;
      }
    }

    &.navbar-offcanvas-right {
      left: auto;
      right: -$offcanvas-width;

      &.offcanvas-transform.in {
        transform: translateX(-$offcanvas-width);
      }

      &.offcanvas-position.in {
        left: auto;
        right: 0;
      }
    }

    .dropdown {
      &.active .caret {
        border-top: 0;
        border-bottom: 4px solid;
      }
    }

    .dropdown-menu {
      position: relative;
      width: 100%;
      border: inherit;
      box-shadow: none;
      transition: height $offcanvas-animation-time ease-in;

      &.shown {
        display: block;
        margin-bottom: 10px;
      }
    }
  }
}

.offcanvas-toggle {
  background: $offcanvas-toggle-background;

  .icon-bar {
    background: $offcanvas-toggle-bars-color;
    transition: all .25s ease-in-out;
  }

  &.is-open {
    .icon-bar:nth-child(1) {
      transform: rotate(45deg) translate(5px, 4px);
    }

    .icon-bar:nth-child(2) {
      opacity: 0;
    }

    .icon-bar:nth-child(3) {
      transform: rotate(-45deg) translate(4px, -4px);
    }
  }
}
